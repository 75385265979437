// Avoid `console` errors in browsers that lack a console.
(function() {
	var method;
	var noop = function () {};
	var methods = [
		'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
		'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
		'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
		'timeStamp', 'trace', 'warn'
	];
	var length = methods.length;
	var console = (window.console = window.console || {});

	while (length--) {
		method = methods[length];

		// Only stub undefined methods.
		if (!console[method]) {
			console[method] = noop;
		}
	}
}());


// Product Zoom
$(function() {
	$("#image").elevateZoom({
		zoomType: 'inner',
		cursor: 'crosshair',
		gallery: 'product__thumbs',
		galleryActiveClass: 'active',
		easing : true,
		zoomWindowWidth: 590,
		zoomWindowHeight: 590,
		loadingIcon: 'catalog/view/theme/sekta/image/loading.gif',
	});
});


// Owl Carousel
/*$(document).ready(function() {
	$("#product-additional-images").owlCarousel({
		items : 4,
		pagination : false,
		navigation : true,
		navigationText : [" "," "],
		scrollPerPage: false,
	});
	$(".product-additional-image-next").click(function(){
		owl.trigger('owl.next');
	})
	$(".product-additional-image-prev").click(function(){
		owl.trigger('owl.prev');
	})
});*/


// Nivo Slider
$(function() {
	$("#banner1").nivoSlider({
		effect: 'fade',
		directionNav: false,
		controlNav: false,
	});
});